export const transformDataURL = (
  blob: Blob
): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      if (blob) {
        reader.readAsDataURL(blob);
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const downloadFile = (blob: Blob, filename: string): void => {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  link.setAttribute("rel", "noopener noreferrer");
  link.setAttribute("target", "_blank");
  link.click();
};

export const isImg = (filename: string): boolean => {
  const imageExt = /\.(jpg|jpeg|png)$/g;
  return (filename.match(imageExt)?.length ?? 0) > 0;
};
