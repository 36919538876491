import { HttpClient } from "@/services/http.service";
import { Api } from "@/models/class/api.class";
import { AxiosError } from "axios";
import { ErrorResponseModel } from "@/models/interface/http.interface";
import { ResponseUploadData } from "@/models/interface-v2/file.interface";

class FileServices extends HttpClient {
  public constructor() {
    super();
  }

  public baseUrl(): string {
    return Api.uploadFileModul;
  }

  public uploadFile(payload: FormData): Promise<ResponseUploadData> {
    return this.post<ResponseUploadData, FormData>(Api.uploadFileModul, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  public deleteFile(fileName: string): Promise<boolean> {
    return this.delete<boolean>(`${Api.uploadFileModul}/${fileName}`)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  public getFile(filename: string): Promise<Blob> {
    return this.get<Blob>(`${Api.uploadFileModul}/${filename}`, {
      responseType: "blob"
    })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }
}

export const fileServices = new FileServices();
