import { Api } from "@/models/class/api.class";
import { DataListGeneralJournal, ParamDownloadGLReport, RequestGeneralJournalCreate, ResponseGeneralJournalCreate, ResponseListGeneralJournal } from "@interface/general-journal.interface";
import { ErrorResponseModel, RequestQueryParamsModel } from "@/models/interface/http.interface";
import { HttpClient } from "@/services/http.service";
import { AxiosError } from "axios";

export class GeneralJournalServices extends HttpClient {

  public constructor() {
    super();

    this.listGeneralJournal = this.listGeneralJournal.bind(this);
    this.createGeneralJournal = this.createGeneralJournal.bind(this);
    this.detailGeneralJournal = this.detailGeneralJournal.bind(this);
  }

  listGeneralJournal(params: RequestQueryParamsModel): Promise<ResponseListGeneralJournal> {
    return this.get<ResponseListGeneralJournal>(Api.generalJournal, { params })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  updateGeneralJournal(id: string, payload: RequestGeneralJournalCreate): Promise<ResponseGeneralJournalCreate> {
    return this.put<ResponseGeneralJournalCreate, ResponseGeneralJournalCreate>(`${Api.generalJournal}/${id}`, payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  postingGeneralJournal(id: string, payload: RequestGeneralJournalCreate): Promise<ResponseGeneralJournalCreate> {
    return this.put<ResponseGeneralJournalCreate, ResponseGeneralJournalCreate>(`${Api.generalJournal}/posting/${id}`, payload)
        .then(this.success)
        .catch((error: AxiosError<ErrorResponseModel>) => {
          throw this.error(error);
        });
  }

  createGeneralJournal(payload: RequestGeneralJournalCreate): Promise<ResponseGeneralJournalCreate> {
    return this.post<ResponseGeneralJournalCreate, ResponseGeneralJournalCreate>(Api.generalJournal, payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  detailGeneralJournal(id: string): Promise<DataListGeneralJournal> {
    return this.get<DataListGeneralJournal>(Api.generalJournal + `/${id}`)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  downloadList(params: RequestQueryParamsModel): Promise<ArrayBuffer> {
    return this.get<ArrayBuffer>(`${Api.generalJournal}/report/download`, {
      responseType: "arraybuffer",
      params,
    })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  downloadDetail(id: string): Promise<ArrayBuffer> {
    return this.get<ArrayBuffer>(`${Api.generalJournal}/report/download/${id}`, {
      responseType: "arraybuffer",
    })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  downloadGLReport(params: ParamDownloadGLReport): Promise<ArrayBuffer> {
    return this.get<ArrayBuffer>(`${Api.generalJournal}/gl-report/download`, {
      responseType: "arraybuffer",
      params,
    })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

}

export const generalJournalServices = new GeneralJournalServices();
